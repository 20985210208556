import './App.css';
import MainPage from './components/MainPage/MainPage';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import { BrowserRouter as Router, Routes, Route, } from 'react-router-dom'
// import ogokilogo from './Assets/images/OGOKI_LOGO.png'

import Login from './components/Authentication/login_component'
import SignUp from './components/Authentication/signup_component'
import Reset from './components/Authentication/reset'

const AuthWrapper = ({children}) =>{
  return (
    <div className="auth-wrapper">
        <div className="auth-inner">
            {children}
        </div>
    </div>
  )
}

// const NavBar = () =>{
//   const location = useLocation();
 
//   if(location.pathname === '/home'){
//     return null;
//   }

//   return(
//     <nav className="navbar navbar-expand-lg navbar-light fixed-top">
//       <div className="container">
//         <Link className="navbar-brand" to={'/sign-in'}>
//          <img src={ogokilogo} alt=""  style={{ width: '15vw',borderWidth:'20px' ,height: 'auto'}}/>
//         </Link>
//         <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
//           <ul className="navbar-nav ml-auto">
//             <li className="nav-item">
//               <Link className="nav-link" to={'/sign-in'}>
//                 Login
//               </Link>
//             </li>
//             <li className="nav-item">
//               <Link className="nav-link" to={'/sign-up'}>
//                 Sign up
//               </Link>
//             </li>
//           </ul>
//         </div>
//       </div>
//     </nav>
//   )
// }



function App() {
  const isLoggedIn = window.localStorage.getItem("loggedIn"); 

  // const [windowDimensions, setWindowDimensions] = useState({ 
  //   width: window.innerWidth, 
  //   height: window.innerHeight 
  // });

  return (
    <Router>
          {/* <div className='navigator'>
          <NavBar />  
          </div> */}
          
          <div className='noNavContainer'>

            {/* <div className='title'>
              <h1 className='titleContent' style={{ fontSize: windowDimensions.width > 700 ? windowDimensions.width / 30 : windowDimensions.width / 20 }}>
                  Ogoki Learning Showcase
              </h1>
            </div> */}
            <Routes>
              <Route exact path="/" element={isLoggedIn ?
                  <MainPage />
                : 
                
                <AuthWrapper>
                  <Login />
                </AuthWrapper>} 
              />


              <Route path="/sign-in" element={
                <AuthWrapper>
                    <Login />
                </AuthWrapper>
                }               
              />

              <Route path="/sign-up" element={
                <AuthWrapper>
                    <SignUp />
                </AuthWrapper>
              } 
              />

              <Route path="/reset" element={
                <AuthWrapper>
                    <Reset />
                </AuthWrapper>
              } 
              />

              <Route path="/home" element={<MainPage />} />
            </Routes>
          </div>
            
       
    </Router>
  );
}

export default App;
