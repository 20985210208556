import React, { Component } from 'react'
import './AuthenticationPages.css'
import axios from 'axios';

export default class SignUp extends Component {
  constructor(props){
    super(props)
    this.state={
      fname:"",
      lname:"",
      email:"",
      password:"",
      code:"",
      serverCode:"",
      errors: {
        fname: "",
        lname: "",
        email: "",
        password: "",
      }
    };
    this.handleSendEmail = this.handleSendEmail.bind(this);
   this.handleSubmit = this.handleSubmit.bind(this);
   this.handleChange = this.handleChange.bind(this);
  }

  validateName(name) {
    if(name.length <= 0 ){
      return "Name is required.";
    }
    else if(name.length >= 20 ){
      return "Name should be less than 20 characters.";
    }
    else if(!/^[a-zA-Z]*$/.test(name)){
      return "Name can only contain English letters."
    }
    return "";
  }

  validateEmail(email) {
    const regex =  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if(email.length<=0){
      return "Email is required."
    }
    else if(!regex.test(email)){
      return "Invalid Email"
    }
    return "";
  }

  validatePassword(password) {
    if(password.length <=0 ){
      return "Password is required."
    }
    if( password.length < 6){
      return "Password should be over 6 characters."
    }
    return "";
  }
  
  validateCode(code){
    if(code.length <= 0){
      return "Verification code is required."
    }
    return "";
  }

  handleChange(e){
    this.setState({email: e.target.value},()=>{
      this.setState({
        errors:{
          ...this.state.errors,
           email:""
        }
      })
    })
  }


  async handleSendEmail(){
    try{
      const res = await axios.post('https://appshowcaseserver.ogokilearning.com/send-email-verification-code',{email: this.state.email, fname: this.state.fname})

      this.setState({serverCode:res.data});
       console.log(this.state.serverCode)
       console.log(this.state.code)
      alert('Verification code has been sent to your email!');
    }
    catch(error){
      console.error('Failed to send verification code.',error);
    }
  }



  handleSubmit(e){
    e.preventDefault();

    const{ fname, lname, email, password,code,serverCode } = this.state;

    console.log(fname, lname, email, password, code,serverCode)

    let errors = {
      fname: this.validateName(fname),
      lname: this.validateName(lname),
      email: this.validateEmail(email),
      password: this.validatePassword(password),
      code : this.validateCode(code)
    }

    if(Object.values(errors).every(error => error ==="")&& this.state.serverCode !== "" && this.state.code.trim().toString() === this.state.serverCode.toString()){
    
      fetch("https://appshowcaseserver.ogokilearning.com/register",{
        method:"POST",
        crossDomain:true,
        headers:{
          "Content-Type":"application/json",
          Accept:"application/json",
          "Access-Control-Allow-Origin":"*",
        },
        body:JSON.stringify({
          fname: fname,
          lname:lname,
          email:email,
          password:password,
        })    
      }).then((res) => res.json())
      .then((data) => {
        if(data.status === "ok") {
          console.log(data, "userRegister")

          if(window.confirm("Sign up successfully! Do you want to log in?")){
            window.location.href="./sign-in"          
          } 
        } else if (data.error ==="User Exists") {
          alert("User Exists")
        }
      })
    }else if(this.state.code.trim().toString() !== this.state.serverCode.toString()){
      alert('Invalid verification code!');
    }else {
      this.setState({errors});
    }
  }

  render() {
    const {errors} = this.state;

    return (
      <form onSubmit={this.handleSubmit}> 
        <h3>Sign Up</h3>

        <div className="mb-3">
          <label>First name</label>
          <input
            type="text"
            className="form-control"
            placeholder="First name"
            onChange={(e)=> this.setState({fname: e.target.value})}        
          />

          {errors.fname && <div className='error'>{errors.fname}</div>}
        </div>

        <div className="mb-3">
          <label>Last name</label>
          <input
              type="text" 
              className="form-control" 
              placeholder="Last name"
              onChange={(e)=> this.setState({lname: e.target.value})}
              
           />
          {errors.lname && <div className='error'>{errors.lname}</div>}
        </div>

        <div className="mb-3">
          <label>Email address</label>
          <input
            type="test"
            className="form-control"
            placeholder="Enter email"
            onChange={(e) => this.handleChange(e)}
          />
          {errors.email && <div className='error'>{errors.email}</div>}
        </div>

        {this.validateEmail(this.state.email) === ""&& 
        
          <div className="input-group mb-3">
            <button type="button" onClick={this.handleSendEmail} className="btn btn-outline-secondary btn-sm" >
              {this.state.serverCode !== "" ? "Resend" : "Send Code"}
            </button>
            <div>
                <input type="text" 
                className="form-control"
                placeholder="Verification Code" 
                style={{fontSize: 13 }}
                onChange={e => this.setState({ code: e.target.value })}
              />
            
            </div>
            {errors.code && <div className='error'>{errors.code}</div>}
          </div> 
         
        }
        

        <div className="mb-3">
          <label>Password</label>
          <input
            type="password"
            className="form-control"
            placeholder="Enter password"
            onChange={(e)=> this.setState({password: e.target.value})}
            
          />
          {errors.password && <div className='error'>{errors.password}</div>}
        </div>

        <div className="d-grid">
          <button type="submit" className="btn btn-primary">
            Sign Up
          </button>
        </div>
        <p className="forgot-password text-right">
          Already registered <a href="/sign-in">sign in?</a>
        </p>
      </form>
    )
  }
}
