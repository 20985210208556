import React, { useState, useEffect } from 'react';
import Grid from '../Grid/Grid.js';
import './MainPage.css'
import axios from 'axios';

function MainPage() {
    const [userData, setUserData] = useState(null);
    const [isQuoteSent, setIsQuoteSent] = useState(false);

   const getQuote = async () => {

        try {
            const response = await axios.post('https://appshowcaseserver.ogokilearning.com/get-price-guide',{email: userData.email});
            if(response.data === 'Email sent successfully.') {
                alert('The price guide has been sent to your email!');
                setIsQuoteSent(true);
            }
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(()=>{

        fetch("https://appshowcaseserver.ogokilearning.com/userData",{
            method:"POST",
            crossDomain:true,
            headers:{
              "Content-Type":"application/json",
              Accept:"application/json",
              "Access-Control-Allow-Origin":"*",
            },
            body:JSON.stringify({
             token: window.localStorage.getItem("token"),
            })    
          }).then((res) => res.json())
          .then((data) => {
            console.log(data, "userData")      
            setUserData(data.data);

            if(data.data === "token expired"){
                // alert("Account information expired, please login again");
                window.localStorage.clear();
                window.location.href = "./sign-in"
            }
          })
    })

    const logOut=()=>{
        window.localStorage.clear();
        window.location.href="./sign-in";      
    }


    const [windowDimensions, setWindowDimensions] = useState({ 
        width: window.innerWidth, 
        height: window.innerHeight 
    });
    
    useEffect(() => {
        
        function handleResize() {
        setWindowDimensions({
            width: window.innerWidth,
            height: window.innerHeight
        });
        }
    
        window.addEventListener('resize', handleResize);
    
        return () => window.removeEventListener('resize', handleResize);
      }, []); 

  return (
    <div className="contentWrapper">
        <div className='title item1'>
            <h1 className='titleContent' style={{ fontSize: windowDimensions.width > 700 ? windowDimensions.width / 30 : windowDimensions.width / 20 }}>
                Ogoki Learning Showcase
            </h1>
        </div>

        <div className='gridContainer item2'>
            <Grid />
        </div>

        <div className='btnContainer d-flex'>
            <div className="row justify-content-center"> 
                <div className="col-sm-6 text-center">
                    <button onClick={()=>getQuote()} className="item3 logOutBtn flex-grow-1 btn btn-outline-primary px-lg-5 px-md-3 px-sm-2 px-2 py-lg-3 py-md-2 py-sm-1 py-1 my-2" style={{width: '100%', whiteSpace: 'nowrap'}}>
                       {isQuoteSent ? 'Resend' : 'Get Price Guide'}
                    </button>
                </div>

                <div className="col-sm-6 text-center">
                    <button onClick={()=>logOut()} className="item3 logOutBtn flex-grow-1 btn btn-outline-primary px-lg-5 px-md-3 px-sm-2 px-2 py-lg-3 py-md-2 py-sm-1 py-1 my-2" style={{width: '100%', whiteSpace: 'nowrap'}}>
                        Log Out
                    </button>
                </div>
               
               
            </div>
        </div>
    </div>
  )
}

export default MainPage