import React, { Component } from 'react'
import './AuthenticationPages.css'

export default class Reset extends Component {
  constructor(props){
    super(props);
    this.state={
      email:"",
      message:"",
      errors: {
        email: "",
      },
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this)
  }

  validateEmail(email) {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if(email.length<=0){
      return "Email is required."
    }
    else if(!regex.test(email)){
      return "Invalid Email"
    }
    return "";
  }

  handleChange(e){
    this.setState({email: e.target.value},()=>{
      this.setState({
        errors:{
          ...this.state.errors,
           email:""
        }
      })
    })
  }

  handleSubmit(e){

    e.preventDefault();

    const{ email } = this.state;
    console.log(email)

    let errors = {
      email: this.validateEmail(email),
    }

    if(Object.values(errors).every(error => error ==="")){
    fetch("https://appshowcaseserver.ogokilearning.com/forgotpassword",{
      method:"POST",
      crossDomain:true,
      headers:{
        "Content-Type":"application/json",
        Accept:"application/json",
        "Access-Control-Allow-Origin":"*",
      },
      body:JSON.stringify({
        email:email,
      })    
    }).then((res) => res.json())
    .then((data) => {
      console.log(data, "userRegister");      
        this.setState({ message: data.status, });
    })
    }else {
      this.setState({errors});
    } 

  }
  
  render() {
    const {errors, message} = this.state
    return (
      <form onSubmit={this.handleSubmit}>
        <h3>Forgot Password</h3>

        <div className="mb-3">
          <label>Email address</label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter email"
            onChange={(e) => this.handleChange(e)}
          
          />

          {errors.email && <div className='error'>{errors.email}</div>}
        </div>  
        
        <div className="d-grid">
          <button type="submit" className="btn btn-primary">
          {this.state.message === "A password reset link has been sent to your email!" ? "Resend" : "Submit"}
          </button>
          
        </div>
       
        {message && <div className='message'>{message}</div>}
        <p className="forgot-password text-right">
           <a href="/sign-up">Sign Up</a>
        </p>
      </form>
    )
  }
}