import React, { Component } from 'react'
import './AuthenticationPages.css'
import { Container, Row, Col, } from 'react-bootstrap';

export default class Login extends Component {
  constructor(props){
    super(props);
    this.state={
      email:"",
      password:"",
      errors: {
        email: "",
        password: "",
      }
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  validateEmail(email) {
    const regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if(email.length<=0){
      return "Email is required."
    }
    else if(!regex.test(email)){
      return "Invalid Email"
    }
    return "";
  }

  validatePassword(password) {
    if(password.length <=0 ){
      return "Password is required."
    }
    return "";
  }

  handleSubmit(e){
    e.preventDefault();
    const{ email, password } = this.state;
    console.log(email, password)

    let errors = {
      email: this.validateEmail(email),
      password: this.validatePassword(password),
    }

    if(Object.values(errors).every(error => error ==="")){
      fetch("https://appshowcaseserver.ogokilearning.com/login-user",{
        method:"POST",
        crossDomain:true,
        headers:{
          "Content-Type":"application/json",
          Accept:"application/json",
          "Access-Control-Allow-Origin":"*",
        },
        body:JSON.stringify({
          email:email,
          password:password,
        })    
      }).then((res) => res.json())
      .then((data) => {
        console.log(data, "userLogin")
        if(data.status === "ok"){
          window.localStorage.setItem("token", data.data);
          window.localStorage.setItem("loggedIn", true)
          window.location.href ="./home"
        }
        else if(data.error === "Invalid Password"){
          errors.password="Invalid Password"
          this.setState({errors});
        }
        else if(data.error === "User Not Found"){ 
          errors.email = "The email hasn't been registered."
          this.setState({errors});
        }
      })
    }
  else {
    this.setState({errors});
  } 
}

  render() {
    const {errors} = this.state;

    return (
      <Container className="container-padding">
        <Row className="justify-content-md-center">
          <Col xs={12} md={12} lg={12}>

            <form onSubmit={this.handleSubmit}>
            <h5 style={{
              color: 'rgb(230, 145, 47)',
              marginBottom: 20,
              textAlign:'center'
          }}>Ogoki Learning Showcase</h5>

              <div className="mb-3">
                <label>Email address</label>
                <input
                  type="test"
                  className="form-control"
                  placeholder="Enter email"
                  onChange={(e) => this.setState({email:e.target.value})}
                />

                {errors.email && <div className='error'>{errors.email}</div>}

              </div>

              <div className="mb-3">
                <label>Password</label>
                <input
                  type="password"
                  className="form-control"
                  placeholder="Enter password"
                  onChange={(e) => this.setState({password:e.target.value})}
                />
                {errors.password && <div className='error'>{errors.password}</div>}
              </div>

              <div className="d-grid">
                <button type="submit" className="btn btn-primary">
                 Sign In
                </button>
              </div>
             
             
              <Row>
                <Col md={6}>
                  <p className="forgot-password text-left">
                    Don't have <a href="/sign-up">account?</a>
                  </p>
                </Col>
                <Col md={6}>
                  <p className="forgot-password text-right">
                    Forgot <a href="/reset">password?</a>
                  </p>
                </Col>
              </Row>
              
              
            </form>
          </Col>
        </Row>
      </Container>
    )
  }
}
